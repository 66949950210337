import React, {useState} from 'react';
import './ProductList.css';
import ProductItem from "../ProductItem/ProductItem";
import {useTelegram} from "../../hooks/useTelegram";
import { useProducts } from '../../hooks/useServer';
import {useCallback, useEffect} from "react";

const getTotalPrice = (items = []) => {
    try {
        if (!Array.isArray(items)) {
            throw new Error('Invalid type of items');
        }

        return items.reduce((acc, {price, count = 1}) => {
            if (price == null || price === undefined) {
                throw new Error('Item price is null or undefined');
            }

            if (typeof price !== 'number') {
                throw new Error('Item price is not a number');
            }

            if (count == null || count === undefined) {
                throw new Error('Item count is null or undefined');
            }

            if (typeof count !== 'number') {
                throw new Error('Item count is not a number');
            }

            return acc + (price || 0) * (count || 1);
        }, 0);
    } catch (error) {
        console.error('Error in getTotalPrice', error);
        if (error instanceof TypeError || error instanceof ReferenceError) {
            throw error;
        } else {
            throw new Error('Unhandled exception in getTotalPrice');
        }
    }
}


const ProductList = () => {
    const [addedItems, setAddedItems] = useState([]);
    const {tg, queryId} = useTelegram();

    const {products, loading} = useProducts();

    const onSendData = useCallback(() => {
        console.log(`Clicked on send data ${addedItems}`);
        tg.sendData(JSON.stringify(addedItems));
        // const data = {
        //     products: addedItems,
        //     totalPrice: getTotalPrice(addedItems),
        //     queryId,
        // }
        // fetch('http://85.119.146.179:8000/web-data', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(data)
        // })
    }, [addedItems])

    useEffect(() => {
        if (!addedItems || addedItems.length === 0) {
            tg.MainButton.hide();
            return;
        }
        tg.MainButton.show();
        tg.MainButton.setParams({
            text: 'Корзина: ' + getTotalPrice(addedItems) + ' ₽',
            color: '#ffbd08'
        })
    }, [addedItems])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])

    const onAdd = (product) => {
        try {
            if (!product) {
                throw new Error('Product is null');
            }

            const alreadyAdded = addedItems?.find(item => item?.id === product.id);
            let newItems = [];

            if (alreadyAdded) {
                newItems = addedItems.map(item => {
                    if (item.id === product.id) {
                        return {...item, count: item.count + 1};
                    }
                    return item;
                });
            } else {
                newItems = [...addedItems, {...product, count: 1}];
            }

            setAddedItems(newItems);

        } catch (e) {
            console.error('Error in onAdd: could not update addedItems', e);
        }
    }

    const onMinus = (product) => {
        try {
            if (!product) {
                throw new Error('Product is null');
            }

            const alreadyAdded = addedItems?.find(item => item?.id === product.id);
            let newItems = addedItems?.filter(item => item.id !== product.id) || [];

            if (alreadyAdded) {
                newItems = addedItems.map(item => {
                    if (item.id === product.id) {
                        if (item.count > 1) {
                            return {...item, count: item.count - 1};
                        } else {
                            return null;
                        }
                    }
                    return item;
                }).filter(item => item != null);

            }

            setAddedItems(newItems);

        } catch (error) {
            console.error('Error in onMinus: could not update addedItems', error);
        }
    }

    return (
        <div className={'list'}>
            {loading ? (
                <p className={'loading'}>Загрузка...</p>
            ) : (
                products.map(item => (
                    <ProductItem
                        product={item}
                        onAdd={onAdd}
                        onMinus={onMinus}
                        key={item.id}
                        count={addedItems?.find(product => product.id === item.id)?.count || 0}
                        className={'item'}
                    />
                ))
            )}
        </div>
    );
};

export default ProductList;
