import React from 'react';
import './Button.css';

const Button = ({ disabled = false, ...props }) => {
    return (
        <button {...props}  className={'button ' + props.className} disabled={disabled} />
    );
};

export default Button;
