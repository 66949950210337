import { useState, useEffect } from 'react'

export const useProducts = () => {
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true)
            const response = await fetch(
                'https://vkuscitybot.ru/products/',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            const data = await response.json()
            setProducts(data)
            setLoading(false)
        }
        fetchProducts()
    }, [])

    return { products, loading }
}
