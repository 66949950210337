import React from 'react';
import Button from "../Button/Button";
import './ProductItem.css';

const ProductItem = ({product, className, onAdd, onMinus, count}) => {
    const onAddHandler = () => {
        onAdd(product);
    }

    const onMinusHandler = () => {
        onMinus(product);
    }

    return (
        <div className={'product ' + className}>
            <img src={product.images} className={'img'}/>
            <div className={'title'}>{product.title}</div>
            <div className={'description'}>{product.weight} г</div>
            <div className={'price'}>
                {product.price} ₽
            </div>
            <div className="add-btn">
                {count === 0 ? <Button style={{"width": "100%"}} onClick={onAddHandler}>Добавить</Button> : null}
                {count > 0 ? <Button style={{"marginRight": "auto"}} onClick={onMinusHandler}>-</Button> : null}
                {count > 0 ? <Button disabled={true}>{count}</Button> : null}
                {count > 0 ? <Button style={{"marginLeft": "auto"}} onClick={onAddHandler}>+</Button> : null}
            </div>
        </div>
    );
};

export default ProductItem;
